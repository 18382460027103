




import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import { Box, Button, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import Debug from "../components/Debug";
import useSiteMetadata from "../components/SiteMetadata";
import Markdown from "../components/Markdown";
import ButtonBackToMainPage from "../components/ButtonBackToMainPage";


const getDataBACKUP = ({ email, companyFullName, serviceName, ceo, website, address, name, nip, regon }) => (`

# Regulamin Strony internetowej

## Przedmiot Regulaminu

Niniejszy dokument to Regulamin Strony Internetowej udostępnionej pod adresem ${website}, określający zasady i warunki korzystania z niej przez Użytkownika, stanowiąc regulamin świadczenia usług drogą elektroniczną w rozumieniu art. 8 ust. 1 Ustawy o świadczeniu usług drogą elektroniczną. Regulamin określa w szczególności rodzaje i zakres oferowanych usług świadczonych drogą elektroniczną, warunki świadczenia usług drogą elektroniczną, w tym wymagania techniczne niezbędne do współpracy z systemem teleinformatycznym, którym posługuje się Administrator, zakaz dostarczania przez Użytkownika treści o charakterze bezprawnym, warunki zawierania i rozwiązywania umów o świadczenie usług drogą elektroniczną, tryb postępowania reklamacyjnego.

Prosimy o uważne zapoznanie się z tym Regulaminem i skontaktowanie się z nami, jeśli masz jakiekolwiek pytania. Korzystając ze Strony internetowej, wyrażasz zgodę na podleganie temu Regulaminowi oraz naszej Polityce Prywatności, którą znajdziesz pod adresem ${website}/polityka-prywatnosci


## Definicje

W ramach niniejszego Regulaminu używamy określonych terminów, którym nadajemy konkretne i precyzyjne znaczenie. W dalszej części tego dokumentu będziemy posługiwać się następującymi terminami:

**Administrator** – właściciel Strony internetowej oraz powiązanej domeny internetowej ${website}, ${ceo}, prowadzący działalność gospodarczą o nazwie ${name} z adresem ${address}, wpisana do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, nr NIP ${nip}, nr REGON ${regon}, będąca Usługodawcą w rozumieniu Ustawy. Kontakt z Administratorem możliwy jest za pomocą poczty elektronicznej na adres ${email} oraz w formie pisemnej na adres działalności gospodarczej podany wyżej. Działalność gospodarcza Administratora jest zarejestrowana na terenie Polski w Unii Europejskiej i podlega jej prawom.

**Regulamin** – niniejszy dokument, określający prawa i obowiązki Administratora oraz Użytkownika, realizujący obowiązki nałożone na Administratora w Ustawie oraz regulujący warunki korzystania przez Użytkownika ze Strony internetowej oraz określający zakres odpowiedzialności stron Umowy.

**Komunikacja** - usługi umożliwiające komunikację między Użytkownikiem, Administratorem a innymi podmiotami; Komunikacja obejmuje w szczególności możliwość korzystania z formularzy kontaktowych do przekazywania informacji i dokumentów, wymianę wiadomości e-mail pomiędzy Administratorem, a Użytkownikiem oraz połączenia telefoniczne wykonywane na numer udostępniony przez Użytkownika.

**Strona internetowa** - strona internetowa działająca w domenie ${website} pozwalająca na korzystanie z usług świadczonych drogą elektroniczną przez Administratora.

**Użytkownik** – czyli Ty - osoba fizyczna mająca pełną zdolność do czynności prawnych, która zaakceptowała Regulamin świadczenia Usług.

**Ustawa** – ustawa z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz.U. 2013 poz. 1422 z późn. zm.), obowiązująca na terenie Polski.

**Umowa** – umowa o świadczenie usług drogą elektroniczną, zawarta z wykorzystaniem środków porozumiewania się na odległość, zawierana pomiędzy Administratorem, a Tobą, czyli Użytkownikiem, dotycząca świadczenia na Twoją rzecz Usługi oraz prowadzenia Komunikacji. Umowa nie pociąga za sobą żadnych opłat z Twojej strony.

**Usługa** – usługa świadczona przez Administratora na rzecz Użytkownika za pośrednictwem Strony internetowej lub w związku z jej działaniem, przybierająca formę Treści i Newslettera.

**Urządzenie** - sprzęt komputerowy, oprogramowanie i połączenia sieciowe wykorzystywane przez Użytkownika w celu uzyskania dostępu do Treści.

**Treści** – usługi polegające na udzieleniu Użytkownikom dostępu do publikacji tekstowych lub graficznych mających charakter informacyjny.

**Polityka Prywatności** – dokument, w którym informujemy Użytkowników o tym, w jaki sposób zbieramy, przetwarzamy i wykorzystujemy ich dane osobowe oraz w którym informujemy o naszych zasadach dotyczących plików cookies oraz technologii Web Storage przeglądarki internetowej, udostępniany bezpłatnie pod adresem: ${website}/polityka-prywatnosci 

**RODO** – Rozporządzenie (UE) 2016/679 Parlamentu Europejskiego i Rady z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych).

**Newsletter** – usługa marketingowa polegająca na systematycznym wysyłaniu Użytkownikom drogą e-mail bieżących informacji związanych z działaniem Strony internetowej.


## 


## Ogólne warunki świadczenia usług

**Przeznaczenie Strony internetowej.** Strona internetowa przeznaczona jest dla Użytkowników, którzy chcą uzyskać informacje o działalności prowadzonej przez Administratora oraz prowadzić z nim Komunikację.

**Funkcjonalność Strony internetowej i zakres świadczonych Usług.** Strona internetowa umożliwia korzystanie z następujących Usług: dostęp do Treści oraz Newsletter. Strona internetowa może zawierać reklamy podmiotów, produktów lub usług firm trzecich.

**Nieodpłatność korzystania ze Strony internetowej.** Korzystanie z funkcjonalności udostępnionych przez Administratora na Stronie internetowej nie wiąże się z pobieraniem opłat pieniężnych.~~ ~~

**Okres świadczenia Usługi.** Usługa w zakresie Newsletter świadczona jest do czasu wycofania przez Użytkownika zgody. Usługa w zakresie Treści świadczona jest na czas nieograniczony. Usługodawca zastrzega sobie prawo do zaprzestania świadczenia Usługi na rzecz Użytkownika w dowolnym czasie, bez podania przyczyn.

Zakazane jest dostarczanie przez Użytkownika treści o charakterze bezprawnym.

Korzystanie z pełnej funkcjonalności Strony internetowej oraz dostęp do Usługi jest możliwe po spełnieniu przez Użytkownika wszystkich poniższych warunków technicznych:

Minimalne wymagania oprogramowania:



* posiadanie systemu operacyjnego Windows 10 i wyżej, Linux z konsolą graficzną lub Mac OS 9 lub wyżej, najnowsze systemy mobilne: Android, iPhone, Symbian OS, Windows Mobile;
* korzystanie z jednej z następujących przeglądarek: Internet Explorer 10 lub jego późniejsza forma (EDGE), Firefox 16, Chrome 26, Safari 6.1, Opera 15;
* włączona obsługa JavaScript oraz plików cookie;
* posiadanie dostępu do Internetu;
* posiadanie aktywnego iadresu poczty elektronicznej (e-mail);
* posiadanie najnowszej wersji programu Adobe Reader do odczytywania plików o formacie pdf.

Minimalne wymagania sprzętowe:



* procesor: architektura x86 1GHz lub odpowiedniki w pozostałych architekturach;
* pamięć operacyjna: 512MB;
* monitor i karta graficzna: zgodny ze standardem XGA, minimalna rozdzielczość 1024x768 16bit;
* dysk twardy: dowolny obsługujący system operacyjny z minimalnie 100 MB wolnego miejsca;
* mysz lub inny manipulator kursora oraz klawiatura komputerowa;
* karta sieciowa/modem: umożliwiająca dostęp do sieci Internet z prędkością 512kb/s.

Nasza Strona internetowa może działać także na przeglądarkach internetowych niewymienionych powyżej, jednakże jako Administrator nie gwarantujemy, że w takim przypadku będą one funkcjonować zgodnie z założeniami i bez błędów.

Administrator nie gwarantuje poprawnego działania Strony internetowej na urządzeniach mobilnych, w tym m.in., ale nie wyłącznie, smartfon i tablet.

**Negatywne warunki techniczne korzystania ze Strony internetowej oraz oferowanych Usług**. Używanie przestarzałych i nieaktualnych przeglądarek internetowych może wpłynąć na brak możliwości korzystania z pełnej funkcjonalności Strony internetowej. 

W celu zapewnienia bezpieczeństwa przekazu komunikatów w związku z działaniem Strony internetowej, Administrator podejmuje środki techniczne i organizacyjne odpowiednie do stopnia zagrożenia bezpieczeństwa świadczonych Usług elektronicznych.

Ogłoszenia, cenniki, reklamy i inne informacje uwidocznione w Stronie internetowej stanowią zaproszenie do zawarcia Umowy w rozumieniu art. 71 Kodeksu cywilnego.

**Usługa w zakresie Newsletter.** Przed jej rozpoczęciem Użytkownik:



* potwierdza bycie właścicielem podanego adresu poczty elektronicznej e-mail poprzez otwarcie linku (odniesienia) zawartego w treści wiadomości e-mail, automatycznie przesłanej na podany adres.

Niniejszy Regulamin dostępny jest pod adresem ${website}/regulamin gdzie istnieje możliwość pobrania pliku w formacie pdf.

Jeśli przestajesz akceptować Regulamin w obecnej formie lub jeśli w przyszłości nie zaakceptujesz jakichkolwiek zmian w postanowieniach Regulaminu, musisz zaprzestać korzystania z Usług. Kontynuowanie korzystania z Usług oznacza twoje dalsze akceptowanie tych warunków i postanowień.


## Tryb postępowania reklamacyjnego

Nieprawidłowości związane z funkcjonowaniem Strony internetowej Użytkownik może zgłaszać na Adres email.

Wszelkie reklamacje odnoszące się do Usług mogą być składane w formie elektronicznej na Adres email. Usługodawca rozpatruje reklamacje w terminie 14 dni.

Jako Użytkownik w przypadku stwierdzenia stwierdzenia nieprawidłowości lub problemów w funkcjonowaniu naszej Strony internetowej masz prawo dokonać zgłoszenia wspomnianego faktu.

Zgłoszenie, o którym mowa powyżej możesz do nas przesłać drogą poczty elektronicznej za pośrednictwem wiadomości e-mail przesłanej na Adres email.

Zachęcamy, aby zgłoszenie zawierało poniższe informacje, ułatwiające zapoznanie się z Twoją sprawą:



* Twoje imię i nazwisko,
* adres poczty elektronicznej e-mail,
* opis zgłaszanych przez Ciebie zastrzeżeń.

Jeżeli pominiesz którąkolwiek z powyższych informacji, poprosimy Cię o ich, pod rygorem nie przyjęcia Twojego zgłoszenia.

Odpowiedź na Twoje zgłoszenie zostanie przesłana na podany przez Ciebie adres poczty elektronicznej e-mail. Zobowiązujemy się ustosunkować do przekazanych informacji w terminie 15 dni roboczych od dnia dostarczenia prawidłowo uzupełnionego zgłoszenia. 

Jako Administrator mamy prawo odmówić rozpatrzenia zgłoszenia bez podawania przyczyny odmowy.

Nasza odpowiedzialność z tytułu rękojmi wobec Użytkowników będących Przedsiębiorcami w rozumieniu art. 43 (1) ustawy z dnia 23 kwietnia 1964 r. – Kodeks cywilny zostaje całkowicie wyłączona.


## Usługi dostępne na Stronie internetowej

Administrator świadczy na rzecz Użytkownika Usługę przybierając formę:



* dostępu do Treści, 
* otrzymywania Newslettera, 
* prowadzenia Komunikacji.

Dodatkowe formy Usługi niewymienione w tym Regulaminie mogą, ale nie muszą być dostępne dla Użytkownika.


## Ograniczenie odpowiedzialności

Treści zawarte na Stronie internetowej, takie jak tekst, grafika, obrazy oraz inne materiały zawarte na Stronie internetowej mają charakter wyłącznie informacyjny i nie stanowią oferty handlowej.

Administrator nie ponosi odpowiedzialności wynikającej z zastosowania informacji zamieszczonych na Stronie internetowej.

Przyjmujesz do wiadomości i zgadzasz się, że Administrator nie ponosi odpowiedzialności za ewentualne szkody wynikające z:



* przerwy lub opóźnienia w dostępie do Strony internetowej,
* błędnego działania Strony internetowej,
* treści umieszczonych na Stronie internetowej,
* zainfekowania Strony internetowej przez osoby trzecie szkodliwym złośliwym oprogramowaniem (m.in. ale nie wyłącznie: malware, wirusy),
* nieprawidłowego korzystania ze Strony internetowej przez Użytkownika,
* niewłaściwego działania sprzętu komputerowego i oprogramowania Użytkownika,
* podania przez Użytkowników nieprawdziwych, nieaktualnych lub niepełnych danych,
* działania siły wyższej (vis maior), przez którą rozumiane są zdarzenia o charakterze zewnętrznym, przypadkowym lub naturalnym (żywiołowym), niemożliwe do przewidzenia,  o nikłym prawdopodobieństwie zajścia w danej sytuacji, niemożliwe do zapobieżenia oraz takie, nad którymi człowiek nie panuje,
* korzystania przez Użytkownika z naszej Strony internetowej w sposób sprzeczny z postanowieniami Regulaminu,
* problemów technicznych bądź ograniczeń technicznych występujących w sprzęcie komputerowym, systemie teleinformatycznym oraz infrastrukturze telekomunikacyjnej, z których korzysta Użytkownik, a które uniemożliwiają Użytkownikowi korzystanie ze Strony internetowej i oferowanych za jej pośrednictwem Usług,
* z korzystania z usług stron trzecich, do których odnośnik znalazł się na Stronie internetowej,
* z korzystania z usług stron trzecich, które zostały zareklamowane i polecone przez Administratora,
* z jakiekolwiek działań Użytkownika lub z powodu braku ich podjęcia.

Administratorowi przysługuje prawo okresowego zawieszenia świadczenia dostępności Strony internetowej w związku z przeprowadzaniem niezbędnych prac konserwacyjnych lub mających na celu zapewnienie bezpieczeństwa danych Użytkownika oraz w sposób zapewniający jak najmniejszą uciążliwość dla Użytkownika.

Administrator nie ponosi odpowiedzialności za działania Użytkowników i podmiotów trzecich niezgodnie z celem działania Strony internetowej, o którym mowa w niniejszym Regulaminie.

Przyjmujesz do wiadomości i zgadzasz się, że jako Użytkownik:



* ponosisz odpowiedzialność za wszelkie zamieszczane przez siebie treści, w szczególności:
    * treści sprzeczne z obowiązującymi przepisami prawa, powszechnie przyjętymi normami, dobrymi obyczajami lub zasadami współżycia społecznego, 
    * treści mogące w jakikolwiek sposób zagrozić renomie Administratora oraz renomie prowadzonej przez niego działalności, 
    * treści naruszające normy moralne czy obyczajowe, propagujące przemoc, nienawiść, dyskryminację, w tym w szczególności na tle rasowym, etnicznym lub religijnym, 
    * treści obraźliwe oraz zawierające wulgaryzmy,
    * treści naruszające dobra osobiste lub godność innych Użytkowników lub osób trzecich,
    * treści naruszające jakiekolwiek prawa osób trzecich, w tym m.in., ale nie wyłącznie, prawa autorskie, prawa własności przemysłowej, prawa do projektów wynalazczych, znaki towarowe, tajemnicę handlową, tajemnicę przedsiębiorstwa, prawa do wizerunku lub prawa dóbr osób trzecich.
* ponosisz odpowiedzialność za działania lub zaniechania swoich pracowników oraz zleceniobiorców, a także inne osoby zatrudnione na podstawie jakiejkolwiek umowy cywilnoprawnej, korzystające z Usług Administratora.

W razie ewentualnych roszczeń osób trzecich kierowanych do Administratora, z tytułu naruszenia przez Użytkownika jakichkolwiek praw osób trzecich w związku z korzystaniem przez tego Użytkownika ze Strony internetowej, Użytkownik zobowiązany jest:



* zwolnić Administratora od odpowiedzialności, 
* zwrócić poniesione z tego tytułu udokumentowane i uzasadnione koszty (w tym koszty pomocy prawnej),
* zaspokoić roszczenia takich osób trzecich  w sposób przewidziany przepisami prawa,
* zwolnić Administratora z udziału we wszelkich postępowaniach wynikających z tego typu naruszeń, 
* przystąpić w charakterze strony do podjętych postępowań oraz pokryć ich koszty oraz zasądzone odszkodowania.


## Zasady korzystania ze Strony internetowej

Korzystanie z Usług może odbywać się tylko i wyłącznie na zasadach ustalonych w niniejszym Regulaminie.

Użytkownik obowiązany jest do korzystania ze Strony internetowej w sposób zgodny z prawem, dobrymi obyczajami i zasadami współżycia społecznego, mając także na uwadze poszanowanie dóbr osobistych, godności oraz praw własności intelektualnej osób trzecich. Korzystanie ze Strony internetowej nie może naruszać powszechnie uznanych norm społecznych i obyczajowych, w tym również reguł użytkowania sieci Internet.

Użytkownik jest zobowiązany działać w sposób zgodny z niniejszym Regulaminem, z prawem obowiązującym na terenie Rzeczypospolitej Polskiej oraz z zasadami współżycia społecznego oraz dobrymi obyczajami.

Użytkownik obowiązany jest powstrzymać się od wszelkich działań mogących utrudnić bądź destabilizować funkcjonowanie Serwisu lub korzystanie z jego funkcjonalności.

Użytkownik ponosi pełną odpowiedzialność za niewłaściwe korzystanie ze Strony internetowej, w szczególności za wykorzystywanie jej do zamieszczania treści sprzecznych z przepisami prawa, zasadami współżycia społecznego oraz niniejszym Regulaminem.

Użytkownik jest zobowiązany do tego, aby powstrzymać się od kopiowania, modyfikowania, rozpowszechniania, transmitowania lub wykorzystywania w jakikolwiek inny sposób, jakichkolwiek treści i baz udostępnionych na Stronie internetowej, z wyjątkiem korzystania z nich w ramach dozwolonego użytku opisanego w Regulaminie.

Niedozwolone jest podejmowanie jakichkolwiek prób wprowadzania do naszego systemu teleinformatycznego szkodliwych danych oraz złośliwego oprogramowania. Przez złośliwe oprogramowanie rozumiane są tutaj wszelkiego rodzaju aplikacje, kody komputerowe i skrypty mające szkodliwe lub przestępcze działanie w stosunku do naszego systemu komputerowego lub systemu komputerowego któregokolwiek z Użytkowników systemu, do którego zaliczyć można w szczególności, ale nie wyłącznie, takie pliki jak: wirusy, robaki, oprogramowanie szpiegujące (spyware), oprogramowanie szantażujące (ransomware), rejestratory klawiszy (keyloggers) i inne.

Niedozwolone jest podejmowanie działań polegających na blokowaniu, modyfikowaniu lub jakimkolwiek innym zakłócaniu lub przerywaniu przepływu danych w Strony internetowej, a także działań mających na celu ograniczenie w inny sposób korzystania ze Strony internetowej.

Użytkownika obowiązuje bezwzględny i nieograniczony w czasie i miejscu zakaz dostarczania jakichkolwiek Treści o charakterze bezprawnym.

Jeżeli zauważysz, że na naszej Stronie internetowej znajdują się nieodpowiednie treści lub którykolwiek z Użytkowników działa w sposób niezgodny z przepisami powszechnie obowiązującego prawa lub narusza postanowienia niniejszego Regulaminu, prosimy Cię o przesłanie nam informacji o tym na Adres email.

Administrator zobowiązuje się zapewnić Użytkownikowi dostęp do aktualnej informacji o:



* szczególnych zagrożeniach związanych z korzystaniem z usługi świadczonej drogą elektroniczną
* funkcji i celu oprogramowania lub danych niebędących składnikiem treści usługi, wprowadzanych przez Administratora do systemu teleinformatycznego, którym posługuje się Użytkownik.

Użytkownik może w każdej chwili zakończyć korzystanie z naszej usługi świadczonej drogą elektroniczną.

Ogłoszenia, reklamy Administratora oraz informacje o Strony internetowej, podane na Stronie internetowej stanowią zaproszenie do zawarcia umowy w rozumieniu art. 71 ustawy z dnia 23 kwietnia 1964 roku – Kodeks cywilny.

W przypadku, w którym postanowienia niniejszego Regulaminy byłyby niezgodne z przepisami obowiązującymi na terenie Rzeczypospolitej Polski pierwszeństwo mają przepisy obowiązujące na terenie Rzeczypospolitej Polskiej.

Korzystanie z niektórych funkcjonalności Strony internetowej może wymagać zaakceptowania i spełnienia warunków zawartych w regulaminach innych usługodawców, którzy z nami współpracują, a z których usług chce korzystać Użytkownik.

Usługodawca zapewnia prawidłowe funkcjonowanie używanego przez siebie systemu informatycznego i uniemożliwia dostęp do poszczególnych Usług osobom nieuprawnionym. Spełniając to zobowiązanie, Usługodawca stosuje odpowiednie techniki kryptograficzne polegające na weryfikacji Usługobiorcy przy użyciu indywidualnych danych dostępu.

Administrator zabrania korzystania z Strony internetowej i rozpowszechniania przez nią nielegalnych treści lub podejmowania działań, które mogą zakłócić działanie Strony internetowej lub ją uszkodzić. Administrator zabrania korzystania z Strony internetowej i Usług w sposób sprzeczny z prawem.

Administrator zastrzega sobie prawo do wyświetlania wiadomości skierowanych do Usługobiorcy podczas przeglądania treści Strony lub korzystania z Usług.


## Składanie zamówienia

Na dzień dzisiejszy Administrator nie udostępnia Usług związanych z poniesieniem przez Użytkownika kosztów finansowych. 


## Zakończenie świadczenia Usług

Użytkownik może wypowiedzieć umowę o świadczenie Usług bez wskazywania przyczyn przez przesłanie właściwego oświadczenia za pośrednictwem poczty elektronicznej na Adres email. Usługa dostępu do Konta w takim wypadku samoczynnie wygasa po upływie 7 Dni roboczych od doręczenia Usługodawcy oświadczenia woli o jej wypowiedzeniu, chyba że Strony umowy ustalą krótszy termin jej wypowiedzenia.


## Przetwarzanie danych osobowych

Administrator jest administratorem danych w rozumieniu RODO.

Dane Użytkownika są przetwarzane w celu zawarcia, realizacji Usług, rozpatrzenia reklamacji, obrony przed roszczeniami, dochodzeniem roszczeń, jak również w celach statystycznych, analitycznych i marketingowych.

Wszelkie szczegółowe informacje o przetwarzaniu danych osobowych Użytkownika dostępne są w Polityce Prywatności opublikowanej na Stronie internetowej.

Obowiązek zachowania loginu i hasła w poufności. Użytkownik jest zobowiązany do dołożenia szczególnej staranności przy zachowaniu poufności utworzonego loginu oraz hasła dostępu. Użytkownik ponosi pełną odpowiedzialność za udostępnienie tych informacji osobom trzecim, również gdy jest to konsekwencją braku zachowania przez Użytkownika wymaganej ostrożności.


## Bezpieczeństwo Strony internetowej

Zobowiązujemy się do podjęcia działań mających na celu ochronę danych Użytkowników oraz zapewnienia bezpieczeństwa danych wprowadzonych przez Użytkownika do Strony internetowej.

Zobowiązujemy się również do nieudostępniania jakimkolwiek osobom trzecim, jakichkolwiek danych lub informacji dotyczących Użytkownika lub wprowadzonych przez Użytkownika, bez wyraźnej zgody Użytkownika. Wyjątek stanowią sytuacje omówione w Polityce Prywatności.

Zobowiązujemy się do zapewnienia Użytkownikowi dostępu do aktualnych informacji o zaistniałych zagrożeniach związanych z korzystaniem z naszych Usług.

Zabezpieczenia, jakie stosujemy w celu bezpieczeństwa danych wprowadzonych do Strony internetowej stanowią między innymi:



* certyfikat SSL zapewniający poufność transmisji danych przekazywanych przez Internet,
* regularne aktualizacje oprogramowania,
* korzystanie z wtyczki Google Recaptcha, umożliwiającej rozróżnianie czynności w Strony internetowej podejmowanych przez człowieka, od tych wykonywanych przez zautomatyzowane boty.


## Dostawcy zewnętrznych usług

Strona internetowa połączona jest z dostawcami zewnętrznych usług:

Google - administrator: Google LLC z siedzibą w USA lub Google Ireland Limited z siedzibą w Irlandii, usługi: Google Recaptcha, Google Analytics, Google Tags.

Niektóre z zewnętrznych usług mogą zostać uruchomione już w momencie odwiedzenia naszej Strony internetowej, np. w celu weryfikacji czy Stronę internetową przegląda człowiek czy zautomatyzowany bot. Oznacza to, że pewne informacje o tym, że korzystasz ze Strony internetowej zostaną przekazane dostawcy zewnętrznej usługi oraz zostaną zapisane przez niego. Informacje, o których mowa w zdaniu poprzednim zazwyczaj ograniczają się do Twojego adresu IP.

Dokładny zakres danych, który może zostać przekazany zewnętrznemu dostawcy, znajduje się w dokumentach dostawcy dotyczących ochrony danych osobowych. W naszej Polityce Plików Cookies udostępniamy odnośniki do tych dokumentów.


## Anonimizacja wprowadzanych treści

Użytkownik jest zobowiązany, aby nie wprowadzać do Strony internetowej oraz wysyłać poprzez Formularz kontaktowy danych osobowych umożliwiających identyfikację jakiejkolwiek osoby, w tym m.in., ale nie wyłącznie:



* imię lub imiona,
* nazwisko,
* numer PESEL, 
* numer NIP,
* dane adresowe, 
* nazwa lub NIP pracodawcy,
* nazwa prowadzonej działalności gospodarczej lub pracodawcy,
* numer telefonu,
* adres internetowej poczty e-mail,
* adres zamieszkania.

Strona internetowa nie służy wprowadzaniu danych osobowych osób trzecich. Wszelkie dane należy wprowadzać w formie zanonimizowanej, uniemożliwiającej identyfikację jakiejkolwiek osoby, której dane mogłyby dotyczyć.

Wprowadzenie przez Użytkownika niezanonimizowanych danych osobowych stanowi złamanie przez Użytkownika postanowień niniejszego Regulaminu.

Dozwolone jest wprowadzanie treści zawierających dane publicznie dostępne.

W przypadku zapisania przez Użytkownika danych osobowych jakiejkolwiek innej osoby oraz zaistnienia z tego powodu szkód, pełną odpowiedzialność prawną ponosi Użytkownik. Jest on zobowiązany do spłaty wynikłych z tego roszczeń finansowych.

W przypadku nałożeniu na Administratora kar finansowych, grzywn bądź innych opłat i roszczeń z powodu wprowadzenia przez Użytkownika danych osobowych, Administrator ma prawo domagać się od Użytkownika rekompensaty finansowej w pełnej wysokości nałożonej kary, grzywny, opłaty czy roszczeń.


## Warunki licencyjne korzystania ze Strony internetowej

Wszelkie prawa do Strony internetowej, w tym do różnych jej funkcjonalności, w całości i we fragmentach, w szczególności do elementów kodu Strony internetowej, do elementów programistycznych generujących i obsługujących Stronę internetową, do elementów tekstowych, graficznych, multimedialnych, w tym prawa autorskie, prawa pokrewne, prawa własności przemysłowej oraz wszelkie wymagane w danej sytuacji prawa pochodne (licencje) przysługują Administratorowi.

Każdy Użytkownik Strony internetowej jest zobowiązany do przestrzegania niniejszych warunków licencyjnych.

Z chwilą akceptacji Regulaminu przez Użytkownika Administrator udziela Użytkownikowi licencji niewyłącznej na korzystanie ze Strony internetowej zgodnie z jej przeznaczeniem w zakresie Usług opisanych w niniejszym Regulaminie. Licencja jest nieprzenoszalna i zostaje udzielona na czas korzystania przez Użytkownika ze Strony internetowej zgodnie z jej przeznaczeniem i w sposób zgodny z niniejszym Regulaminem. Licencja nie upoważnia Użytkownika do udzielania dalszych licencji.

Użytkownik ma prawo do korzystania ze Strony internetowej tylko i wyłącznie na własny użytek, zgodnie z obowiązującymi przepisami prawa. Zabronione jest udostępnianie Strony internetowej osobom trzecim. Zabronione jest dokonywanie odsprzedaży czy dystrybucji i sprzedaży handlowej dostępu do Strony internetowej. Zabroniona jest dekompilacja i dezasemblacja oprogramowania funkcjonującego w ramach Strony internetowej oraz odtwarzanie jej kodu źródłowego w jakikolwiek sposób.

Przekazywanie osobom trzecim treści zawartych w Strony internetowej dopuszczalne jest wyłącznie przy wykorzystaniu narzędzi udostępnianych w ramach Strony internetowej, które zostały do tego celu przeznaczone.

Licencja nie obejmuje prawa do:



* trwałego lub czasowego zwielokrotniania kodu Strony internetowej w całości lub w części,
* wprowadzania jakichkolwiek modyfikacji w części lub w całości kodu Strony internetowej,
* wprowadzania do obrotu lub rozpowszechniania Strony internetowej, w jakikolwiek sposób, w jakiejkolwiek formie i jakimikolwiek środkami, zarówno odpłatnego jak i nieodpłatnego,
* przesyłania lub udostępniania Strony internetowej w systemach i sieciach komputerowych, systemach dystrybucji aplikacji webowych lub jakichkolwiek innych systemach teleinformatycznych.

Użytkownik nie może przenosić praw przysługujących mu z tytułu korzystania ze Strony internetowej na inne osoby, bez wyraźnej zgody Administratora, wyrażonej w formie pisemnej pod rygorem nieważności. 

W przypadku naruszenia przez Użytkownika zasad korzystania ze Strony internetowej lub warunków udzielonej Licencji, Administrator ma prawo do zablokowania Użytkownikowi dostępu do Strony internetowej oraz cofnięcia udzielonej Licencji na korzystanie ze Strony internetowej. Powyższe nie uchybia prawu Administratora do podjęcia innych przewidzianych prawem działań w związku z dokonanym naruszeniem.


## Ochrona praw autorskich

Użytkownik oświadcza, iż jest mu wiadome, że wszystkie Treści oraz pozostała zawartość Strony internetowej, zamieszczone, publikowane, czy wykorzystywane na Stronie internetowej stanowią przedmiot autorskich praw majątkowych przysługujących Administratorowi lub nabytych przez Administratora uprawnień licencyjnych i innych zgód w zakresie rozpowszechniania, i podlegają ochronie na zasadach właściwych przepisów dotyczących praw autorskich.

Użytkownik jest uprawniony do korzystania z zawartości Strony internetowej, jednak wyłącznie w zakresie dozwolonego użytku osobistego. Wykorzystywanie jakichkolwiek elementów Strony internetowej w innym zakresie jest dopuszczalne wyłącznie na podstawie wyraźnej, uprzedniej zgody, udzielonej przez uprawniony do tego podmiot, na piśmie pod rygorem nieważności oraz w granicach powszechnie obowiązującego prawa. 

Użytkownik, który naruszy jakiekolwiek prawa autorskie podmiotów trzecich, zobowiązany jest do zwolnienia Administratora z wszelkich roszczeń kierowanych do niego w związku z takimi naruszeniami.

Wszelkie oznaczenia handlowe publikowane na Stronie internetowej oraz oznaczenia handlowe umieszczane w reklamach rozpowszechnianych w Serwisie są zarejestrowanymi znakami towarowymi lub nierejestrowanymi oznaczeniami handlowymi i podlegają ochronie na podstawie ustawy Prawo własności przemysłowej, konwencji międzynarodowych, Kodeksu spółek handlowych oraz Kodeksu cywilnego.

Bezwzględnie zabronione jest w szczególności, ale nie wyłącznie:



* odsprzedawanie dostępu do Strony internetowej, 
* tworzenie na podstawie Strony internetowej lub zawartych w niej treści własnych produktów, treści cyfrowych lub usług sprzedawanych dalej,
* kopiowanie zdjęć, logotypów, znaków towarowych i innych grafik,
* kopiowanie materiałów wideo,
* dokonywanie przedruku tekstów czy haseł reklamowych,
* udostępnianie treści Strony internetowej bez pisemnej zgody Administratora lub bez zgody innej osoby trzeciej, która posiada do takich materiałów prawa autorskie.

Bezwzględnie zabronione jest dokonywanie wyżej opisanych czynności przez jakiekolwiek podmioty zewnętrzne lub osoby trzecie w celu późniejszego wykorzystania ich do celów własnych.

Dopuszczanie się działań, które zostały opisane w niniejszym paragrafie jest niezgodne z przepisami obowiązującego prawa i może stanowić podstawę do wszczęcia postępowania cywilnego lub karnego.

Użytkownik wprowadzając do Strony internetowej informacje i treści, zobowiązany jest posiadać prawo do korzystania z tychże materiałów, akceptując, iż wszelka odpowiedzialność tytułem naruszenia praw autorskich do zamieszczanych materiałów obciąża tylko i wyłącznie Użytkownika.

Użytkownik oświadcza, że posiada wszelkie prawa, w tym w szczególności prawa autorskie, do wszystkich przesyłanych materiałów, w szczególności do zdjęć i tekstów, oraz że utwory te są wolne od wad prawnych i nie naruszają żadnych praw osób trzecich oraz innych podmiotów (w razie ewentualnych roszczeń osób trzecich, wynikających z naruszenia tych praw, Użytkownik ponosi całkowitą odpowiedzialność).


## Zmiany Regulaminu

Administrator może dokonywać zmian Regulaminu w przypadku:



* zmiany zakresu i sposobu świadczenia oferowanych w Stronie internetowej Usług,
* zapewnienia bezpieczeństwa informacji, jakie są pozyskiwane lub przetwarzane,
* zmian powszechnie obowiązujących przepisów prawa, które mają wpływ na treść Regulaminu,
* konieczności dostosowania zasad prowadzenia Strony internetowej do nakazów, orzeczeń, postanowień lub wytycznych wynikających z decyzji właściwego w zakresie prowadzenia przez Administratora organu administracji publicznej lub − orzeczenia sądowego znajdującego zastosowanie w zakresie działalności Administratora wpływającego na wzajemne prawa i obowiązki Stron,
* konieczności dokonania korekty oczywistych omyłek lub błędów pisarskich albo uzupełnienia luk lub nieścisłości w Regulaminie niedających się usunąć w  wyniku wykładni zawartych postanowień,
* zaistnienia innych, wyłącznie ważnych przyczyn.

Informacja o zakresie zmian Regulaminu oraz dniu wejścia zmian w życie zostanie udostępniona Użytkownikowi na Koncie lub drogą e-mail, nie później niż 5 dni przed wejściem w życie zmian Regulaminu.

Zmiany wchodzą w życie od dnia wskazanego przez Administratora i odnoszą skutek wobec nowych Użytkowników, którzy skorzystają z Usług od dnia wejścia zmian w życie. Zmiany odnoszą również skutek wobec Użytkowników, którzy w dniu wejścia zmian korzystają z Usługi, w przypadku gdy zmiany: 



* skutkują przyznaniem Użytkownikowi nowych uprawnień, 
* nakładają na Użytkownika nowe obowiązki, którym Użytkownik może być obciążony wyłącznie z woli Użytkownika
* pozostają bez istotnego wpływu na prawa i obowiązki Użytkownika.

W przypadku gdy zmiana Regulaminu skutkuje nałożeniem na Użytkownika nowych obowiązków, których wykonania Użytkownik nie akceptuje, albo ogranicza dotychczas przyznane prawa, Użytkownik uprawniony jest do złożenia oświadczenia o wypowiedzeniu umowy o świadczenie Usługi. W takim przypadku zmiany nie odnoszą skutku wobec Użytkownika, a umowa, której przedmiotem jest świadczenie Usług wygasa.


## Postanowienia końcowe

Klauzula salwatoryjna. Uznanie poszczególnych postanowień niniejszego Regulaminu w sposób przewidziany prawem za nieważne bądź nieskuteczne nie wpływa na ważność czy skuteczność pozostałych postanowień Regulaminu. W miejsce nieważnego postanowienia zastosowana będzie reguła, która jest najbliższa celom nieważnego postanowienia i całego niniejszego Regulaminu.

Stosowane przepisy i postanowienia Umów. W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie przepisy prawa obowiązującego na terytorium RP, a także postanowienia innych zaakceptowanych przez Użytkownika w ramach Serwisu wzorców umownych Na zasadzie art. 6 Konwencji Narodów Zjednoczonych o umowach międzynarodowej sprzedaży towarów sporządzonej w Wiedniu dnia 11 kwietnia 1980 r. Strony wyłączają zastosowanie niniejszej Konwencji do umów zawartych na podstawie Regulaminu.

Korzystając z naszych Usług mogą zostać Ci zaprezentowane materiały reklamowe dostarczone przez strony trzecie.

W przypadku braku możliwości polubownego rozwiązania sporu pomiędzy Adminstratorem, a Użytkownikiem, w tym za pośrednictwem mediacji, sądem właściwym dla rozpatrywania zaistniałego sporu będzie sąd właściwy dla siedziby Administratora.

W sprawach nieuregulowanych niniejszym Regulaminem zastosowanie będą miały właściwe przepisy prawa polskiego, w szczególności przepisy ustawy z dnia 23 kwietnia 1964 roku – Kodeks cywilny (Dz. U. z 2020 r. poz. 1740), ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną (Dz. U. z 2020 r. poz. 344), ustawy z dnia 30 maja 2014 roku o prawach konsumenta (Dz. U. z 2020 r. poz. 287), ustawa z dnia 10 maja 2018 roku o ochronie danych osobowych (Dz. U. z 2019 r. poz. 1781), ustawa z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych (Dz. U. z 2019 r. poz. 1231, z 2020 r. poz. 288) oraz inne właściwe.

Regulamin wchodzi w życie z dniem 1.09.2024 r. i z tym dniem zastępuje dotychczas obowiązujący regulamin. Regulamin znajduje zastosowania do umów zawartych od dnia jego wejścia w życie.
`)



const getData = ({ email, companyFullName, serviceName, companyShortestName, ceo, website, address, name, nip, regon }) => (`

# Regulamin strony internetowej ${website}

Strona internetowa ${website} jest własnością ${companyFullName}.

Kontakt do nas: ${email}

## I. Postanowienia ogólne.

1. Poniżej znajdziesz regulamin, w którym opisane są informacje m.in. o sposobie, w jaki udostępniamy nieodpłatnie treści na stronie ${website} oraz świadczymy usługę dostępną drogą elektroniczną w postaci Formularza kontaktowego.

2. Regulamin skierowany jest zarówno do konsumentów, jak i do przedsiębiorców korzystających ze Sklepu Internetowego, chyba że dane postanowienie Regulaminu stanowi inaczej i jest skierowane wyłącznie do konsumentów albo do przedsiębiorców.

## II. Definicje na potrzeby regulaminu, przyjmuje się następujące znaczenie poniższych pojęć:

1. **Strona internetowa/witryna internetowa** – strona internetowa Usługodawcy dostępna pod adresem internetowym: ${website}

2. **Usługodawca** – ${companyFullName}.

3. **Usługobiorca** - osoba fizyczna, osoba prawna albo jednostka organizacyjna nieposiadająca osobowości prawnej, która korzysta z usługi świadczonej drogą elektroniczną;

4. **Usługa elektroniczna** – usługa świadczona drogą elektroniczną przez Usługodawcę na rzecz Usługobiorcy za pośrednictwem witryny internetowej.

5. **Formularz Kontaktowy** – usługa elektroniczna, interaktywny formularz dostępny na stronie internetowej, umożliwiający przesłanie wiadomości jeżeli chcesz się ze nami skontaktować.

## III. Treści dostępne nieodpłatnie.

1. Na stronie internetowej publicznie dostępne są treści tekstowe, graficzne, dźwiękowe i audiowizualne, z którymi możesz zapoznawać się nieodpłatnie i anonimowo, tj. bez konieczności podawania swoich danych osobowych.

2. Przeglądanie publicznie dostępnej zawartości strony wymaga posiadania dostępu do internetu i urządzenia wraz z oprogramowaniem pozwalającego na przeglądanie zawartości internetu.

## IV. Prawa autorskie.

Prawa autorskie do treści zawartych na stronie internetowej (treści tekstowych, fotografii, grafik, utworów audio i video) przysługują nam lub podmiotom z nami współpracującym. Korzystanie z tych treści z naruszeniem zasad niniejszego regulaminu lub prawa autorskiego może skutkować odpowiedzialnością cywilną lub karną.


## V. Usługi elektroniczne w na stronie internetowej ${website}

1. Na stronie internetowej dostępna jest usługa elektroniczna w formie Formularza kontaktowego.

2. Usługa Formularza kontaktowego – świadczenie usługi Formularza inicjowane jest przez Ciebie poprzez użycie widocznego na stronie internetowej okna Formularza kontaktowego. Usługa polega na umożliwieniu wpisania do Formularza Twojego imienia i nazwiska, adresu e-mail oraz wiadomości jaką chcesz nam przekazać, a następnie kliknięcie pola „Wyślij”. Na Twoje pytanie odpowiemy na podany przez Ciebie adres e-mail.


## VI. Tryb postępowania reklamacyjnego dotyczący Usług Elektronicznych

1. Reklamacje związane ze świadczeniem usług elektronicznych przez Usługodawcę oraz pozostałe reklamacje związanie z działaniem witryny internetowej, Usługobiorca może składać:
a. pisemnie na adres: ${address}
b. w formie elektronicznej za pośrednictwem poczty elektronicznej na adres: ${email}
2. Zaleca się podanie przez Usługobiorcę w opisie reklamacji: (1) informacji i okoliczności dotyczących przedmiotu reklamacji, w szczególności rodzaju i daty wystąpienia nieprawidłowości; (2) żądania Usługobiorcy; oraz (3) danych kontaktowych składającego reklamację – ułatwi to i przyspieszy rozpatrzenie reklamacji przez Usługodawcę.

Wymogi podane w zdaniu poprzednim mają formę jedynie zalecenia i nie wpływają na skuteczność reklamacji złożonych z pominięciem zalecanego opisu reklamacji.

3. Ustosunkowanie się do reklamacji przez Usługodawcę następuje niezwłocznie, nie później niż w terminie 14 dni kalendarzowych od dnia jej złożenia.

## VII. Dane osobowe i pliki cookies.

1. Jeżeli w Formularzu kontaktowym podajesz nam swoje dane osobowe, jesteśmy ich Administratorem w rozumieniu przepisów o ochronie danych osobowych.
2. Witryna internetowa wykorzystuje technologię plików cookies.
3. Szczegóły związane z przetwarzaniem danych osobowych oraz plikami cookies opisane są w Polityce prywatności i plików cookies mieszczącej się pod adresem ${website}/polityka-prywatnosci

## VIII. Zmiany regulaminu.

1. Możemy modyfikować ten regulamin z ważnych powodów, takich jak zmiany prawne, technologiczne lub biznesowe. Do umów zawartych przed zmianą regulaminu stosuje się regulamin w brzmieniu obowiązującym w chwili zawarcia umowy.
2. Regulamin w obecnej wersji obowiązuje od dnia 16 październik 2024 r.

     `)



export const IndexPage = ({

}) => {

    const siteMeta = useSiteMetadata();


    return (
        <Layout variant="regulamin">

            <Container maxWidth="sm" sx={{

                position: "relative"
            }}>


                <ButtonBackToMainPage />

                {/* <Typography variant="h1">Polityka prywatności</Typography> */}


                {/* <Markdown>{data}</Markdown> */}

                <Markdown>{getData(siteMeta)}</Markdown>


            </Container>
        </Layout>

    );
};




export default IndexPage;

export const Head = () => {

    const { tel,
        linkgooglemap,
        address,
        title,
        description,
        siteUrl
    } = useSiteMetadata();

    return (<>
        {/* https://www.searchenginejournal.com/important-tags-seo/156440/ */}
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* <meta name="robots" content="nofollow"> */}

        {/* <link rel="canonical" href={siteUrl} /> */}

        <meta property="og:title" content={title} />

        <meta property="og:description" content={description} />

        <meta property="og:image" content={`${siteUrl}/img/map.jpg`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* <meta charset="UTF-8" /> */}


    </>


    )
}


